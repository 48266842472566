<template>
  <div>
    <div style="text-align: center">
      <el-image :src="require('@/assets/image/TradeTip/commitment-title.png')"
          style="width: 336px;margin-top:60px"></el-image>
      <el-image :src="require('@/assets/image/TradeTip/commitment.png')"
          style="width: 759px;margin-top:54px"></el-image>
    </div>
    <div class="after-sale">
      <div class="title1"><span></span>售后服务保障</div>
      <div class="title2">售后服务电话：15556652227（9:30-00:30）</div>
      <router-link tag="div" :to="{name:'TradeTip',query:{id:'112',parent_id:'83'}}" class="cus-but" @click="cusSerCenter=true">
        售后服务投诉通道<i class="el-icon-caret-right"></i>
      </router-link>
      <!--   售后保障背景图   -->
      <el-image class="person" :src="require('@/assets/image/TradeTip/afterSalePerson.png')"></el-image>

      <el-image style="margin: 0 0 64px" :src="require('@/assets/image/TradeTip/afterSaleWechat.png')"></el-image>
    </div>
  </div>
</template>

<script>

export default {
  name: "afterSale",
  data() {
    return {
      list: [
        {text: '1. 不足2000不够立案？跨省追回，并判刑', link: 'https://pxb7.com/gamedetails?game_id=10&id=23017'},
        {text: '2.找回狗、特嚣张、舔狗多、被抓后秒认怂', link: 'https://pxb7.com/gamedetails?game_id=8&id=47076'},
        {text: '3.跨省追回账号，我们一直在做！', link: 'https://pxb7.com/gamedetails?game_id=10&id=42319'},
        {text: '4.制裁各种找回狗，帮忙追回账号！', link: 'https://pxb7.com/gamedetails?game_id=15&id=70'},
        {text: '5.连夜跨省制裁租号骗代购的骗子！', link: 'https://pxb7.com/gamedetails?game_id=15&id=70'},
      ]
    }
  },
  methods: {
    goToLink(url) {
      window.open(url)
    },
  }
}
</script>

<style scoped lang="less">
.after-sale {
  padding-left: 84px;
  padding-top: 100px;
  position: relative;

  .title1 {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 500;
    color: #1a2b58;
    margin: 0 0 14px;
    span{
      display: inline-block;
      width: 8px;
      height: 19px;
      background: #1a2b58;
      margin: 0 13px 0 0;
    }
  }

  .title2 {
    font-size: 16px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    color: #4f667b;
    margin:  0 0 0 21px;
  }

  .title3{
    margin: 0 0 17px 21px;
    width: 720px;
    font-size: 16px;
    font-family: PingFang SC,PingFangSC-Regular;
    font-weight: 500;
    text-align: LEFT;
    color: #4f667b;
    line-height: 34px;
    span{
      display: block;
    }
  }

  .cus-but {
    width: 175px;
    height: 30px;
    background: #FFC957;
    border-radius: 15px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 30px;
    text-align: center;
    margin: 17px 0 81px 21px;
    cursor: pointer;
  }

  .person {
    width: 348px;
    position: absolute;
    right: 0;
    top: -12px;
  }

  .case-title {
    margin-top: 85px;
    width: 469px;
  }

  .case-list {
    margin-top: 30px;
    margin-bottom: 81px;

    .case-item {
      display: flex;
      align-items: center;
      width: 454px;
      cursor: pointer;
      margin-bottom: 20px;

      &:hover {
        .title {
          color: #FFC957;
        }

        .ellipsis {
          border-color: #FFC957;
        }

        .detail {
          color: #FFC957;
        }
      }

      .title {
        font-size: 16px;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 300;
        color: #8FA2BD;
        line-height: 22px;
      }

      .ellipsis {
        border-bottom: 2px dotted #97A9C2;
        height: 1px;
        flex: 1;
        margin: 0 10px;
      }

      .detail {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #5571A9;
        line-height: 20px;
      }
    }
  }
}
</style>
