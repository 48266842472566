<template>
  <div class="cases">
    <div class="title">
      <img src="@/assets/image/Legal/casesTitle.png" alt="">
    </div>
    <div class="body">
      <div v-if="!id" id="list">
        <div class="head">
          <div :class="{'headItemSelect':item.cat_name === headSelect}" class="headItem" @click="changeHeadSelect(item)"
               v-for="(item,index) in headList" :key="index" >
            <img
              :src="require(item.cat_name === headSelect ? '@/assets/image/Legal/casesHead.png':'@/assets/image/Legal/casesHeadS.png')"
              alt="">
            {{ item.cat_name }}
          </div>
        </div>
        <div class="list">
          <div class="item" v-for="(item,index) in announcementList" :key="index" @click="id = item.id">
            <div class="text">{{ item.title }}</div>
            <div class="date">{{ item.add_time|moment }}</div>
            <div class="btn">查看</div>
          </div>
          <div class="pages" v-if="totalCount !== 0">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page.sync="currentPage"
              :page-size="PageSize"
              layout="prev, pager, next, jumper"
              :total="totalCount">
            </el-pagination>
          </div>

        </div>
      </div>
      <div v-else class="content">
        <a @click="id = ''" class="contentBack">返回{{headSelect}}列表</a>
        <div id="flex">
          <div class="contentTitle">{{ announcementList[0].title }}</div>
          <div class="contentInfo">
            <div class="contentDate">发布时间：{{ announcementList[0].add_time|moment }}</div>
            <div class="contentClick">点击数：{{ announcementList[0].click }}</div>
          </div>
          <div id="content" v-html="announcementList[0].content"></div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {apiArticleList,apiArticleCategory} from "@/request/API";

export default {
  name: "cases",
  data() {
    return {
      id: '',
      announcementList: [
        {title: ''}
      ],
      headList: [],
      headSelect: '找回案例',
      cat_id:'2',

      // 默认显示第几页
      currentPage: 1,
      // 总条数，根据接口获取数据长度(注意：这里不能为空)
      totalCount: 0,
      // 默认每页显示的条数（可修改）
      PageSize: 10,
    }
  },
  methods: {
    /**
     * 获取追回案例列表
     * @param id
     */
    getArticleList(id,cat_id) {
      apiArticleList({
        id: id,
        cat_id: this.cat_id,
        page:this.currentPage
      }).then(res => {
        this.announcementList = res.data.list
        this.totalCount = res.data.total
      })
    },

    //分页监听事件
    handleCurrentChange(val) {
      this.currentPage = val
      this.getArticleList()
    },

    /**
     * 切换文章分类
     * @param item
     */
    changeHeadSelect(item){
      this.headSelect = item.cat_name
      this.cat_id = item.cat_id
      this.currentPage = 1
      this.getArticleList()
    },
    /**
     * 请求文章分类列表
     */
    getArticleCategory(){
      apiArticleCategory({
        pid:'4'
      }).then(res=>{
        this.headList = res.data
      })
    }

  },
  mounted() {
    this.getArticleList()
    this.getArticleCategory()
  },
  watch: {
    id(val, oldVal) {//普通的watch监听]
      this.getArticleList(val)
    },
  },
}
</script>

<style scoped>
#content {
  /*padding: 50px 40px;*/
  /*width: 871px;*/
  overflow: auto;
  margin: 0;
}

#content >>> img {
  width: 100% !important;
  /*height: 100% !important;*/
  object-fit: contain !important;
}

</style>
<style scoped lang="less">
.cases {
  height: calc(100% - 48px);
  padding: 48px 0 0;
  position: relative;
  .title{
    position: absolute;
    top: 10px;
    left: 161px;
  }

  .body {
    background:url("../../assets/image/Legal/casesBG.png") no-repeat , linear-gradient(180deg, #2e4770, #c5dbe8 100%);
    padding: 168px 35px 71px ;
    height: calc(100% - 239px);
    #list {
      height: 100%;
      .head {
        display: flex;
        flex-direction: row;
        align-items: center;

        .headItem {
          width: 139px;
          height: 46px;
          background: rgba(255,255,255,0.20);
          border-radius: 10px 10px 0 0 ;
          margin: 0 10px 0 0;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          font-size: 16px;
          font-family: PingFang SC, PingFang SC-Semibold;
          font-weight: 600;
          color: #ffffff;
          img{
            margin: 0 10px 0 0;
          }
        }
        .headItemSelect{
          color: #222543;
          background: #ffffff;
        }
      }

      .list {
        height: calc(100% - 56px);
        margin: 10px 0 0 ;
        background: #FFFFFF;
        border-radius: 10px;
        .item {
          padding: 24px 50px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          border-radius: 10px;
          .text {
            width: 500px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 16px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            color: #5b5b5b;
          }

          .date {
            font-size: 14px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            color: #8f8f8f;
          }

          .btn {
            width: 100px;
            height: 30px;
            background: #ffd355;
            border-radius: 17px;
            font-size: 16px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            text-align: center;
            color: #000000;
            line-height: 30px;

          }
        }

        .item:hover {
          background: #fff9ee;
        }

        .pages {
          height: 94px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }


    }

    .content {
      //width: 871px;
      //height: 810px;
      background: #FFFFFF;
      padding: 10px 40px 50px;
      border-radius: 10px;

      .contentBack {
        display: block;
        padding: 0 0 0 18px;
        font-size: 14px;
        font-family: PingFangSC-Thin, PingFang SC;
        font-weight: 300;
        color: #8F8F8F;
        line-height: 20px;
        margin: 20px 0 28px;
        cursor: pointer;
        background: url("../../assets/image/Home/articleBack.png") no-repeat;
        background-position: 0 5px;
      }

      .contentBack:hover {
        color: #FCAD13;
        background: url("../../assets/image/Home/articleBackHover.png") no-repeat;
        background-position: 0 5px;
      }

      #flex {
        display: flex;
        flex-direction: column;
        align-items: center;

        .contentTitle {
          font-size: 20px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 500;
          color: #5B5B5B;
          line-height: 22px;
          margin: 0 0 10px 0;
        }

        .contentInfo {
          display: flex;
          flex-direction: row;
          align-items: center;
          height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Thin, PingFang SC;
          font-weight: 300;
          color: #8F8F8F;
          line-height: 20px;
          margin: 0 0 10px 0;

          div {
            margin: 0 15px;
          }
        }

        #content {

        }

        #signature {

        }
      }

    }
  }


}

</style>
