<template>
  <div class="ql-container ql-snow">
    <p class="legalContent ql-editor" v-html="pageInfo"></p>
  </div>
</template>
<script>
import {apiPageInfo} from "@/request/API";

export default {
  name: '',
  props: {
    key1: String,
  },
  components: {

  },
  data () {
    return {
      pageInfo:''
    }
  },
  methods: {
    getPageInfo(){
      apiPageInfo({
        id:this.key1
      }).then(res=>{
        this.pageInfo = res.data.content
      })
    }
  },
  mounted () {
    this.getPageInfo()
  },
  watch: {
    key1(val, oldVal){//普通的watch监听
      this.key1 = val
      this.getPageInfo()
    },
  },
  computed: {

  }
}
</script>

<style  scoped>
.ql-container{
  border: none !important;
}
.legalContent{
  padding: 0;
  /*width: 871px;*/
  overflow: auto;
  margin: 0;
}
.legalContent >>> img{
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
}
</style>
